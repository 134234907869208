
import GSpacer from './goose/g-spacer';
import CmsRichtext from './cms-richtext';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-disclaimer',
  components: {
    GSpacer,
    CmsRichtext,
  },
  mixins: [cmsComponent],
  computed: {
    richtextProps() {
      return {
        blok: {
          content: this.blok.disclaimer.content.disclaimer,
        },
        slug: this.slug,
      };
    },
  },
};
