
import GExitPopUpBlog from './goose/g-exit-pop-up-blog';
import MarketoForm from './marketo-form';
import richtext from '~/utils/richtext';
import generalExitPopUp from 'mixins/generalExitPopUp';

export default {
  name: 'blog-exit-pop-up-modal',
  components: {
    GExitPopUpBlog,
    MarketoForm,
  },
  mixins: [generalExitPopUp],
  computed: {
    exitPopUpLeftSideProps() {
      return {
        header: richtext(this.$store.state.modal.blogModalData.header, true),
        paragraph: richtext(
          this.$store.state.modal.blogModalData.paragraph,
          true
        ),
        image: this.$store.state.modal.blogModalData.image,
        tag: this.$store.state.modal.blogModalData.tag,
      };
    },
  },
};
