
import GCardInline from './goose/g-card-inline';
import cmsComponent from '~/mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-card-inline',
  components: {
    GCardInline,
  },
  mixins: [cmsComponent],
  methods: {
    bindProps(card) {
      return {
        title: card.title,
        icon: card.icon,
        url: parseLinkHref(card.link),
      };
    },
  },
};
