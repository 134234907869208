
export default {
  name: 'storyblok-component',
  components: {
    GHighlight: () => import('../goose/g-highlight'),
    GStatsQuote: () => import('../goose/g-stats-quote'),
    GButtonV2: () => import('../goose/g-button-v2'),
    GCompanyRating: () => import('../goose/g-company-rating'),
    Cms2ColsScrollScrollingContainer: () =>
      import('./cms-2-cols-scroll-scrolling-container.vue'),
  },
  props: {
    blok: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      componentOptions: [
        'g-highlight',
        'g-stats-quote',
        'g-button-v2',
        'cms-2-cols-scroll-scrolling-container',
      ],
    };
  },
};
