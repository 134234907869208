
import GCompanyRating from './goose/g-company-rating';
import cmsComponent from '~/mixins/cmsComponent';
export default {
  name: 'cms-company-rating',
  components: {
    GCompanyRating,
  },
  mixins: [cmsComponent],
};
