
import GButtonV2 from './goose/g-button-v2';
import GContainer from './goose/g-container';
import parseLinkHref from '~/utils/parseLinkHref';
import GLazyImage from './goose/g-lazy-image';

export default {
  name: 'cms-error',
  components: { GContainer, GButtonV2, GLazyImage },
  props: {
    statusCode: {
      type: String,
      default: '404',
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      fetched: false,
      errorObject: {},
    };
  },
  async created() {
    const { app, env } = this.$nuxt.context;
    const version = env.storiesVersion;
    try {
      await app.$storyapi
        .get(`${this.url}`, {
          version,
        })
        .then(res => {
          const story = res.data.story.content.content[0];
          this.errorObject = {
            hideStatusCode: story.hideStatusCode,
            MarketoFormErrMsg: story.MarketoFormErrMsg,
            headline: story.headline,
            subheadline: story.subheadline,
            image: story.image,
            links: story.links.map(link => {
              return {
                text: link.text,
                link: link.link,
              };
            }),
          };
          this.fetched = true;
        });
    } catch (error) {
      console.error(`There was an error [cms-error] (url:${this.url}):`, error);
    }
  },
  methods: {
    getLinkHref(link) {
      return link.cached_url !== 'us/' ? parseLinkHref(link) : '/';
    },
  },
};
