
import GCards4ColLinks from './goose/g-cards-4-col-links';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-cards-4-col-links',
  components: {
    GCards4ColLinks,
  },
  mixins: [cmsComponent],
  computed: {
    cardsSerialized() {
      return this.blok.cards.map(card => {
        return {
          title: card.title,
          subheadline: richtext(card.paragraph, true),
          image: card.icon,
          linkHref: parseLinkHref(card.link),
        };
      });
    },
  },
};
