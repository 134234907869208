
import MarketoForm from './marketo-form';
import GStatsQuote from './goose/g-stats-quote';
import bookAConsultationData from '../lib/storyblok-local-json/book-a-consultation.json';
import GLazyImage from './goose/g-lazy-image';
import richtext from '~/utils/richtext';

export default {
  name: 'book-a-consultation',
  components: {
    GLazyImage,
    GStatsQuote,
    MarketoForm,
    LogoDeputy: () => import('assets/svg/icons/logo-deputy.svg'),
  },
  data() {
    return {
      modalData: bookAConsultationData.content.content[0],
      ipCountry: this.$store.state.ipCountry,
    };
  },
  computed: {
    getSublineContent() {
      return richtext(this.content('subline'), true);
    },
  },
  methods: {
    content(field) {
      const regionalData = this.modalData[`${field}_${this.ipCountry}`];
      const globalData = this.modalData[`${field}_us`];
      if (field === 'image') {
        return regionalData?.imagePublicUrl ? regionalData : globalData;
      }
      return regionalData || globalData;
    },
  },
};
