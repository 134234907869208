
import GCardOutline from './goose/g-card-outline';
import GButtonV2 from './goose/g-button-v2';
import GCardInline from './goose/g-card-inline';
import cmsComponent from 'mixins/cmsComponent';
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-column-grouped-cards',
  components: {
    GCardOutline,
    GCardInline,
    GButtonV2,
  },
  mixins: [cmsComponent],
  computed: {
    linkText() {
      return this.blok.link_text !== ''
        ? this.blok.link_text
        : this.getLinkHref(this.blok.link_url);
    },
    otherCards() {
      const otherCards = [];
      const sizeColumns = this.blok.columns.length;
      const sizeCards = this.blok.columns[0].cards.length;
      this.blok.columns.map((column, iColumn) => {
        const indexColumn = iColumn + 1;
        column.cards.map((card, iCard) => {
          const indexCard = iCard + 1;
          const gridRow =
            indexColumn < 3 ? indexCard + 1 : indexCard + sizeCards + 2;
          const gridCol =
            indexColumn < 3 ? indexColumn : Math.floor(indexColumn / 2);
          card.gridClass = `row-${gridRow} col-${gridCol}`;
        });
        column.cards.map((otherCard, iOtherCard) => {
          const indexOtherCard =
            iOtherCard === 0 ? iColumn : iColumn + sizeColumns;
          otherCards[indexOtherCard] = otherCard;
        });
      });
      return otherCards;
    },
  },
  methods: {
    getLinkHref(linkObj) {
      return linkObj.cached_url !== '' ? parseLinkHref(linkObj) : '';
    },
    mainCard(mainCard) {
      const mainCardObj = mainCard[0];
      return {
        icon: mainCardObj.icon,
        title: mainCardObj.title,
        text: richtext(mainCardObj.paragraph, true),
        url: this.getLinkHref(mainCardObj.link),
        type: 'border',
      };
    },
    otherCard(cardObj) {
      return {
        icon: cardObj.icon,
        title: cardObj.title,
        url: this.getLinkHref(cardObj.link),
      };
    },
    mainCardGrid(i) {
      const index = i + 1;
      const size = this.blok.columns[0].cards.length;
      const gridRow = index < 3 ? 1 : size + 2;
      const gridCol = index < 3 ? index : Math.floor(index / 2);
      return `row-${gridRow} col-${gridCol}`;
    },
  },
};
