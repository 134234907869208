
import GCardOutlineSquiggle from './goose/g-card-outline-squiggle';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
export default {
  name: 'cms-card-outline-squiggle',
  components: {
    GCardOutlineSquiggle,
  },
  mixins: [cmsComponent],
  computed: {
    card() {
      return {
        headline: this.blok.headline,
        subline: this.blok.subline,
        image: this.blok.image,
        ctaLink: parseLinkHref(this.blok.link),
        ctaText: this.blok.cta,
      };
    },
  },
};
