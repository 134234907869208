import { render, staticRenderFns } from "./cms-2-cols-scroll.vue?vue&type=template&id=56c9b653&scoped=true&"
import script from "./cms-2-cols-scroll.vue?vue&type=script&lang=js&"
export * from "./cms-2-cols-scroll.vue?vue&type=script&lang=js&"
import style0 from "./cms-2-cols-scroll.vue?vue&type=style&index=0&id=56c9b653&prod&lang=postcss&"
import style1 from "./cms-2-cols-scroll.vue?vue&type=style&index=1&id=56c9b653&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c9b653",
  null
  
)

export default component.exports