
import GLawStateHeading from './goose/g-law-state-heading';
import cmsComponent from 'mixins/cmsComponent';
import PageScaffolder from '~/components/page-scaffolder';
import Intersect from '~/utils/intersect';
import kebabCase from 'lodash/kebabCase';

export default {
  name: 'cms-card-law',
  components: {
    PageScaffolder,
    GLawStateHeading,
    Intersect,
  },
  mixins: [cmsComponent],
  computed: {
    headingProps() {
      return {
        title: this.blok.law.content.heading.content.title,
        tagline: this.blok.law.content.heading.content.tagline,
        icon: this.blok.law.content.heading.content.icon,
      };
    },
    initialBlocks() {
      return Number(this.blok.law.content.initial_blocks || 0);
    },
    uniqueName() {
      return kebabCase(this.blok.law.content.heading.content.title);
    },
  },
  methods: {
    updateSelected(entries) {
      const { intersectionRatio, boundingClientRect } = entries[0];
      const isEnterOverHeightElement =
        boundingClientRect.height > window.innerHeight &&
        intersectionRatio > 0.4;

      if (intersectionRatio === 1 || isEnterOverHeightElement) {
        this.$root.$emit('update-law-menu-selected', this.uniqueName);
      }
    },
  },
};
