
import richtext from '~/utils/richtext';
import marked from 'marked';
import cmsComponent from 'mixins/cmsComponent';

const compiler = require('vue-template-compiler');

export default {
  name: 'cms-blog-richtext',
  mixins: [cmsComponent],
  data() {
    return {
      template: null,
    };
  },
  computed: {
    checkContent() {
      if (typeof this.blok.content !== 'undefined') {
        // based on: https://www.storyblok.com/docs/richtext-field#migration-from-the-markdown-field
        // old blog post have been imported as markup, the content will be a string
        if (typeof this.blok.content === 'string') {
          // we need to make sure all the links in the blog post are linked to the region
          // we also replace all wpblog.deputec.com/dptycms/wp-content with deputy.com
          const content = this.replaceContent(this.blok.content);
          return `<article>${this.lazyLoadImage(marked(content))}</article>`;
        } else {
          // back to string then we replace the values and back to object
          const content = this.replaceContent(
            JSON.stringify(this.blok.content)
          );
          const formatedContent = this.lazyLoadImage(
            richtext(JSON.parse(content))
          );
          return `<article>${formatedContent}</article>`;
        }
      }
      return '';
    },
  },
  methods: {
    replaceContent(content) {
      const region = this.region === 'us' ? '' : `${this.region}/`;
      const regex = /\bwww\.deputy\.com\/(?!api-doc)(us|au|gb)?\/?\b/g;
      const regexAssets = /wpblog\.deputec\.com\/dptycms\/wp-content\//g;
      return content
        .replace(regex, `www.deputy.com/${region}`)
        .replace(regexAssets, 'www.deputy.com/')
        .replace(/(us|au|gb)\/uploads/g, 'uploads');
    },
    lazyLoadImage(content) {
      return content.replace(/<img (alt|src)="/g, '<img loading="lazy" $1="');
    },
  },
  render(h) {
    return h('div', { class: 'cms-blog-richtext' }, [
      h(compiler.compileToFunctions(this.checkContent)),
    ]);
  },
};
