
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';
import GComparison2Cols from './goose/g-comparison-2-cols';

export default {
  name: 'cms-comparison-2-cols',
  components: {
    GSpacer,
    GComparison2Cols,
  },
  mixins: [cmsComponent],
  computed: {
    comparisonProps() {
      return {
        col1Header: this.blok.column_1_header,
        col2Header: this.blok.column_2_header,
        col1Items: this.itemsParser(this.blok.column_1_content),
        col2Items: this.itemsParser(this.blok.column_2_content),
      };
    },
  },
  methods: {
    itemsParser(stringInput) {
      const regex = /\[([^\]]+)\]\s*([^\n]+)/g;
      const matches = stringInput.matchAll(regex);
      const result = [];

      for (const match of matches) {
        result.push({
          icon: match[1],
          text: match[2].trim(),
        });
      }

      return result;
    },
  },
};
