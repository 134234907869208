
import GCtaLight from './goose/g-cta-light';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-cta-light',
  components: {
    GCtaLight,
  },
  mixins: [cmsComponent],
  computed: {
    capterraStats() {
      return {
        score: this.stats['capterra_score'],
        total_reviews: this.stats['capterra_total_reviews'],
      };
    },
  },
};
