
import Intersect from '~/utils/intersect';

export default {
  name: 'grid-intersect',
  components: { Intersect },
  props: {
    intersectThreshold: {
      type: Array,
      default: () => {
        return [0];
      },
    },
    intersectRootMargin: {
      type: String,
      default: '-33%',
    },
  },
  data() {
    return {
      intersectInitalized: false,
    };
  },
  methods: {
    triggerIntersect(direction) {
      // the intersect triggers when it is initalised
      // this can cause funny behaviour as the page loads
      // we only want to know when a change is due to user scrolling
      if (!this.intersectInitalized) {
        this.intersectInitalized = true;
        return;
      }

      this.$emit(direction);
    },
  },
};
