
import GBlogRawContent from './goose/g-blog-raw-content';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-blog-raw-content',
  components: {
    GBlogRawContent,
  },
  mixins: [cmsComponent],
  computed: {
    parseBlok() {
      const blok = JSON.parse(JSON.stringify(this.blok));
      blok.raw_content = blok.raw_content.replace(
        /<img/gm,
        '<img loading="lazy"'
      );
      return blok;
    },
  },
};
