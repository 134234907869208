
import GButtonV2 from './goose/g-button-v2';
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-button-v2',
  components: {
    GButtonV2,
    GSpacer,
  },
  mixins: [cmsComponent],
};
