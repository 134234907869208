
import richtext from '~/utils/richtext';
import parseLinkHref from '~/utils/parseLinkHref';

import GCardLandscapeRoundedCorners from './goose/g-card-landscape-rounded-corners';
import cmsComponent from 'mixins/cmsComponent';
export default {
  name: 'cms-card-landscape-rounded-corners',
  components: {
    GCardLandscapeRoundedCorners,
  },
  mixins: [cmsComponent],
  methods: {
    changeText(p) {
      if (typeof p !== 'undefined') {
        return richtext(p, false, true);
      }
      return '';
    },
    formatLink(obj) {
      return parseLinkHref(obj);
    },
  },
};
