
import GColourHeadingCards from './goose/g-colour-heading-cards';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';

export default {
  name: 'cms-colour-heading-cards',
  components: {
    GColourHeadingCards,
  },
  mixins: [cmsComponent],
  computed: {
    cards() {
      return this.blok.cards.map(({ title, mins_to_complete, image, link }) => {
        return {
          title,
          image,
          href: parseLinkHref(link),
          minsToComplete: mins_to_complete,
        };
      });
    },
  },
};
