
import GCardToModalTarget from './goose/g-card-to-modal-target';
import cmsComponent from 'mixins/cmsComponent';

export default {
  name: 'cms-card-to-modal-target',
  components: {
    GCardToModalTarget,
  },
  mixins: [cmsComponent],
  computed: {
    parseBlok() {
      return {
        headline: this.blok.headline,
        subline: this.blok.subline,
        image: this.blok.image,
        ctaText: this.blok.cta_text,
        ctaTarget: this.blok.cta_target,
        items: this.blok.items,
      };
    },
  },
};
