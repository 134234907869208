
import GHeadlineDivider from './goose/g-headline-divider';
import cmsComponent from 'mixins/cmsComponent';
import GWorkplaceLogos from './goose/g-workplace-logos';

export default {
  name: 'cms-award-badges',
  components: {
    GWorkplaceLogos,
    GHeadlineDivider,
  },
  mixins: [cmsComponent],
  data() {
    return {
      countryNames: {
        au: 'Australia',
        gb: 'The UK',
        us: 'America',
      },
    };
  },
  computed: {
    headline() {
      if (typeof this.blok.header === 'undefined' || this.blok.header === '') {
        return `${
          this.countryNames[this.region]
        }'s leading scheduling software`;
      }
      return this.interpolateStats(this.blok.header);
    },
    badges() {
      const awards =
        this.blok?.awards ||
        this.blok?.award_pack?.content?.awards ||
        this.blok?.award_pack ||
        [];
      return awards.map(award => {
        return {
          content: {
            greyscale_brand: award.content.badge_image,
          },
        };
      });
    },
  },
};
