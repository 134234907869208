
import GCards4ColsIconsLinks from './goose/g-cards-4-cols-icons-links';
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
import richtext from '~/utils/richtext';

export default {
  name: 'cms-cards-4-cols-icons-links',
  components: {
    GCards4ColsIconsLinks,
    GSpacer,
    GContainer,
  },
  mixins: [cmsComponent],
  computed: {
    cards() {
      return this.blok.cards.map(card => {
        return {
          title: card.title,
          href: parseLinkHref(card.link),
          paragraph: richtext(card.paragraph, false, true),
          icon: card.icon,
        };
      });
    },
  },
};
