
import cmsComponent from 'mixins/cmsComponent';
import GContainer from './goose/g-container';
import GSpacer from './goose/g-spacer';
import GContentReveal from './goose/g-content-reveal';
import GLazyImage from './goose/g-lazy-image';

export default {
  name: 'cms-competitor-comparison',
  components: {
    GContentReveal,
    GContainer,
    GSpacer,
    GLazyImage,
  },
  mixins: [cmsComponent],
};
