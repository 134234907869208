
import cmsComponent from 'mixins/cmsComponent';
import GridIntersect from './grid-intersect.vue';
import GMedia from '../goose/g-media';
import StoryblokComponent from './storyblok-component.vue';

const __default__ = {
  name: 'cms-2-cols-scroll',
  components: { GridIntersect, GMedia, StoryblokComponent },
  mixins: [cmsComponent],
  data() {
    return {
      // initalized to -1 so the first container animates in
      activeContainer: 0,
      transitionStepDelay: 0.25,
      transitionStepDuration: 1,
    };
  },
  computed: {
    activeMedia() {
      return this.blok.containers[this.activeContainer]?.stationaryContent[0]
        ?.media[0];
    },
    activeMediaLength() {
      const media = this.activeMedia;
      if (media?.component === 'g-image-animation') {
        return media.images.length;
      }
      return 1;
    },
  },
  beforeMount() {
    this.$store.dispatch('page/initalizeScrollDirection');
  },
  methods: {
    intersectLeaving(i) {
      // there is a gap between when one intersect enters and another leaves.
      // this means if a user scrolls right until the next intersect (e.g. #2) triggers 'enter'
      // but before the previous intersect (e.g. #1) leaves and the user scrolls back,
      // the previous intersect (#1) won't retrigger
      // so we need to use the leave from the next intersect (#2) to trigger an update
      if (i === this.activeContainer) {
        if (this.$store.getters['page/scrollDirection'] === 'up') {
          this.activeContainer = Math.max(i - 1, 0);
        } else {
          this.activeContainer = Math.min(
            i + 1,
            this.blok.containers.length - 1
          );
        }
      }
    },
    containerMedia(container) {
      return container.stationaryContent?.[0]?.media[0];
    },
  },
};

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "4c90dc25": (_vm.activeMediaLength)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__