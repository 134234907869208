
import GCardHighlightPrice from './goose/g-card-highlight-price';
import cmsComponent from 'mixins/cmsComponent';
import parseLinkHref from '~/utils/parseLinkHref';
import richtext from '~/utils/richtext';
import displayCosts from 'mixins/displayCosts';

export default {
  name: 'cms-card-highlight-price',
  components: {
    GCardHighlightPrice,
  },
  mixins: [cmsComponent, displayCosts],
  data() {
    return {
      mounted: false,
    };
  },
  computed: {
    getSecondSubheading() {
      if (typeof this.blok.second_subheading !== 'undefined') {
        return richtext(this.blok.second_subheading, true);
      }
      return '';
    },
  },
  mounted() {
    // Since pricing is rendered on the client side, the server-side render might contain
    // the wrong pricing. We shouldn't show any server-side pricing until the component has been
    // mounted
    this.plan = this.blok.plan;
    this.mounted = true;
  },
  methods: {
    parseLinkHref,
    createChecklist(list) {
      return list.map(item => {
        return { text: item };
      });
    },
    getSubheading(price) {
      return (
        this.getHidePriceMessage(price) ?? richtext(this.blok.subheading, true)
      );
    },
  },
};
